import { Dispatch, useReducer } from "react";
import { reducer, initialState, MenuAction } from ".";

const useHeaderDropdownState = () => {
  const [state, dispatchPureAction] = useReducer(reducer, initialState);

  /**
   * Inspired by redux-thunk, this function will :
   *
   * - Handle composite actions (which will dispatch other, pure actions)
   * - Default to dispatching a pure action if the action type is not a thunk.
   */
  const dispatch: Dispatch<MenuAction> = (action) => {
    switch (action.type) {
      // Schedule the dropdown to be closed.
      case "SCHEDULE_CLOSE": {
        dispatchPureAction({
          type: "SET_CLOSE_TIMER",
          payload: setTimeout(() => {
            dispatchPureAction({ type: "CLOSE" });
          }, 250),
        });

        break;
      }

      // When opening a new dropdown, cancel the closing which was scheduled.
      case "SOFT_OPENED": {
        dispatchPureAction({ type: "CANCEL_SCHEDULED_CLOSE" });
        dispatchPureAction(action);

        break;
      }

      // Default to dispatching the relevant pure action
      default: {
        dispatchPureAction(action);
      }
    }
  };

  return { state, dispatch };
};

export default useHeaderDropdownState;
