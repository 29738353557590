import {
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Box,
  VStack,
  Text,
  HStack
} from "@chakra-ui/react";
import { FC, Fragment } from "react";
import { HeaderSection } from "../types";
import Advertisement from "./Advertisement";
import HeaderSectionPanel from "./HeaderSectionPanel";
import { PrismicLink, ShiIcon } from "src/atoms";

const HeaderAccordionItem: FC<{ section: HeaderSection }> = ({ section }) => {
  const renderColumns = (column: any, isFirst: boolean) => {
    switch (column?.__typename) {
      case "PrismicSiteNavbarV2DataBodyAdvertisement":
        if (!section.primary?.show_custom_block_on_mobile) {
          return null;
        }

        if (column.items.length >= 1) {
          return (
            <Box>
              <Advertisement data={column.items[0]} />
            </Box>
          );
        }

        return null;
      case "PrismicSiteNavbarV2DataBodyDropdownColumn":
        const { primary, panels } = column;

        return (
          <Box
            display={primary?.white_background ? "block" : "none"}
            paddingX="space-16"
            paddingTop="0"
            paddingBottom="space-32"
          >
            <VStack spacing="0" align="flex-start">
              {panels?.map((panel: Gatsby.SiteNavbarV2DropdownPanelFragment) => {
                return (
                  <HeaderSectionPanel
                    key={panel.id}
                    panel={panel}
                    background={primary?.white_background}
                    isFirst={isFirst}
                  />
                );
              })}
            </VStack>
          </Box>
        );
      default:
        return null;
    }
  };

  return (
    <AccordionItem border="none">
      {({ isExpanded }) => (
        <Fragment>
          <AccordionButton
            justifyContent="space-between"
            paddingY="space-20"
            paddingX="0"
            color="text-primary"
            _hover={{ bg: "inherit" }}
          >
            <Text
              as="div"
              fontSize="font-15"
              lineHeight="none"
              fontWeight="medium"
            >
              {section.primary?.title}
            </Text>
            <ShiIcon
              name="chevron"
              width="20px"
              transform={`scale(1.25) ${isExpanded ? "rotate(180deg)" : "rotate(0deg)"}`}
            />
          </AccordionButton>

          <AccordionPanel p="0">
            {section.columns?.map((column, index) => {
              return <Fragment key={index}>{renderColumns(column, index === 0)}</Fragment>;
            })}

            {section.primary?.cta_label && section.primary?.show_custom_block_on_mobile ? (
              <PrismicLink
                eventLabel={section.primary.cta_label}
                link={section.primary.cta_link!}
                param={section.primary.cta_link_param}
              >
                <HStack
                  spacing="space-16"
                  w="full"
                  p="space-20"
                  mb="space-32"
                  alignItems="center"
                  justifyContent="space-between"
                  bg="greyLight-4"
                  borderRadius="lg"
                >
                  <Text
                    fontSize="font-15"
                    lineHeight="short"
                    fontFamily="body"
                  >
                    {section.primary.cta_label}
                  </Text>

                  <ShiIcon name="arrowicon" boxSize="18px" />
                </HStack>
              </PrismicLink>
            ) : null}
          </AccordionPanel>
        </Fragment>
      )}
    </AccordionItem>
  );
};

export default HeaderAccordionItem;
