import { FC } from "react";
import { List, Box, BoxProps, Heading, Wrap } from "@chakra-ui/react";

import HeaderSectionButton from "../components/HeaderSectionButton";

const HeaderSectionPanel: FC<
  {
    panel: Gatsby.SiteNavbarV2DropdownPanelFragment;
    background?: boolean;
    isFirst?: boolean;
  } & BoxProps
> = ({ panel, background, isFirst, ...rest }) => {
  const { primary, items } = panel;

  const isLabelOnlyLinks = items?.every(
    (item) => !item?.icon?.document,
  );

  return (
    <Box {...rest} w="full">
      {primary?.title && (
        <Box
          mb={{ base: "space-16", lg: "space-40" }}
        >
          <Heading
            fontFamily="body"
            paddingY={{ base: "space-8", md: "0" }}
            color={{ base: "greyLight-0", md: "grey-0" }}
            fontSize={{ base: "font-15", md: "font-18" }}
            lineHeight="1"
            whiteSpace="nowrap"
            letterSpacing="0"
          >
            {primary.title}
          </Heading>
        </Box>
      )}
      <List
        mt={!primary?.title && !isFirst ? { base: '-16px', md: '0' } : '0'}
        spacing={{
          base: "space-16",
          md: primary.columns ? "0" : "space-24",
        }}
        display={{
          base: isLabelOnlyLinks ? "none" : "block",
          md: primary.columns ? "grid" : "block",
        }}
        gridTemplateColumns={primary.columns ? `repeat(${primary.columns}, 1fr)` : "none"}
        rowGap={primary.columns ? "space-24" : "0"}
        columnGap={primary.columns ? { md: "space-24", lg: "space-72" } : "0"}
        w="full"
      >
        {items?.map((item, index) => {
          if (!item) return;

          const type =
            item.icon?.document && item.label
              ? "primary"
              : "secondary";

          return (
            <li key={index}>
              <HeaderSectionButton
                item={item}
                background={background!}
                type={type}
              />
            </li>
          );
        })}
      </List>
      {isLabelOnlyLinks ? (
        <Wrap display={{ md: "none" }}>
          {items?.map((item, index) => {
            if (!item) return;

            const type = "tag";

            return (
              <li key={index}>
                <HeaderSectionButton
                  item={item}
                  background={background!}
                  type={type}
                />
              </li>
            );
          })}
        </Wrap>
      ) : null}
    </Box>
  );
};

export default HeaderSectionPanel;
