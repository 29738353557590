import { FC, useEffect, useState } from "react";
import { Box, Button, Flex, VStack, HStack, Spacer, keyframes, Text } from "@chakra-ui/react";
import { slide } from "../utils";
import useDropdownState from "../hook";
import { AnimatePresence, motion } from "framer-motion";
import { HeaderSection } from "../../../types";
import HeaderSectionPanel from "src/components/SiteNavbar/components/HeaderSectionPanel";
import Advertisement, { ADVERTISEMENT_MIN_WIDTH } from "src/components/SiteNavbar/components/Advertisement";
import { NAVBAR_HEIGHT } from "src/slices/Header";
import { IconBox, PrismicLink, ShiIcon } from "src/atoms";
import Carousel from "src/atoms/rebrand/Carousel";
import { SvgOrImg } from "src/components/SvgOrImg";

const opacityIn = keyframes({
  "0%": {
    opacity: 0,
  },
  "100%": {
    opacity: 1,
  },
});

const SiteHeaderDropdown: FC<{
  data: HeaderSection;
  menuIndex: number;
}> = ({ data: { primary, columns }, menuIndex }) => {
  const [shouldHidePopover, setShouldHidePopover] = useState(false);

  const { ref, dispatch, isForceOpened, isSoftOpened } = useDropdownState({
    index: menuIndex,
  });

  useEffect(() => {
    let timeout: NodeJS.Timeout | undefined = undefined;

    if (!isForceOpened && !isSoftOpened) {
      timeout = setTimeout(() => setShouldHidePopover(true), 250);
    } else {
      clearTimeout(timeout);
      setShouldHidePopover(false);
    }

    return () => {
      clearTimeout(timeout);
    };
  }, [isForceOpened, isSoftOpened]);

  if (!primary) return null;

  const getPanelWidth = (
    screenSize: string,
    isLarge: boolean,
    hasWhiteBackground?: boolean,
  ) => {
    if (isLarge) {
      return "566px";
    } else if (hasWhiteBackground === false) {
      return "initial";
    } else {
      return screenSize === "lg" ? "420px" : "320px";
    }
  };

  const renderColumns = (column: any, index: number, totalSections: number) => {
    switch (column?.__typename) {

      case "PrismicSiteNavbarV2DataBodyDropdownColumn":
        const isLarge =
          column.panels &&
          totalSections <= 2 &&
          column.panels.some((panel: Gatsby.SiteNavbarV2DropdownPanelFragment) =>
            panel?.items?.some(
              (item) => item?.icon?.document,
            ),
          );

        const hasWhiteBackground = column.primary?.white_background;

        return (
          <Flex
            key={index}
            paddingY={{ md: "space-20", lg: "space-40" }}
            paddingLeft={{ md: "space-20", lg: hasWhiteBackground && index > 0 ? "space-72" : "space-40" }}
            paddingRight={
              index === totalSections - 1
                ? {
                    md: "space-20",
                    lg: "space-40",
                  }
                : 0
            }
            maxWidth={{
              md: getPanelWidth("md", isLarge, hasWhiteBackground),
              lg: getPanelWidth("lg", isLarge, hasWhiteBackground),
            }}
            w="full"
            marginLeft={
              !hasWhiteBackground && index !== 0
                ? { md: "space-20", lg: "space-72" }
                : 0
            }
            bg={hasWhiteBackground ? "white" : "greyLight-4"}
          >
            <VStack spacing={{ base: "space-20", md: "space-40" }} align="start">
              {column.panels.map(
                (
                  panel: Gatsby.SiteNavbarV2DropdownPanelFragment,
                  index: number,
                ) => {
                  return (
                    <HeaderSectionPanel
                      panel={panel}
                      background={hasWhiteBackground}
                      key={index}
                    />
                  );
                },
              )}
            </VStack>
          </Flex>
        );

      case "PrismicSiteNavbarV2DataBodyAdvertisement":
        if (column.items.length === 1) {
          return (
            <Flex
              key={index}
              boxSizing="content-box"
              padding={{ md: "space-20", lg: "space-40" }}
              paddingLeft={{ md: "space-20", lg: "space-72" }}
              minW={ADVERTISEMENT_MIN_WIDTH}
              maxW={ADVERTISEMENT_MIN_WIDTH}
              flex={1}
              flexShrink={0}
              bg="white"
            >
              <Advertisement data={column.items[0]} margin="0" minHeight="108px" />
            </Flex>
          );
        }

        return (
          <Flex
            key={index}
            boxSizing="content-box"
            padding={{ md: "space-20", lg: "space-40" }}
            paddingLeft={{ md: "space-20", lg: "space-72" }}
            minW={ADVERTISEMENT_MIN_WIDTH}
            maxW={ADVERTISEMENT_MIN_WIDTH}
            flex={1}
            flexShrink={0}
            bg="white"
            overflow="hidden"
          >
            <Carousel
              indicators="dots"
              carouselWrapperProps={{
                paddingTop: "0",
                spacing: "0",
                templateColumns: {
                  base: `repeat(1, auto)`,
                  md: `repeat(1, auto)`,
                  lg: `repeat(1, auto)`,
                }
              }}
              breakPointValue={{ base: 1, md: 1, lg: 1 }}
              items={column.items.map((item) => (
                <Advertisement key={item.id} data={item} margin="0" />
              ))}
            />
          </Flex>
        );

      default:
        return null;
    }
  };

  return (
    <Box>
      <Button
        ref={ref.buttonRef}
        variant="link-navbar"
        px="space-12"
        whiteSpace="nowrap"
        textAlign="left"
        _first={{ paddingLeft: 0 }}
        onMouseEnter={() => dispatch({ type: "SOFT_OPENED", payload: menuIndex })}
        onMouseLeave={() => dispatch({ type: "SCHEDULE_CLOSE" })}
      >
        {primary.title}

        <ShiIcon name="chevron" boxSize="16px" ml="1.5" />
      </Button>

      <Box ref={ref.popoverRef}>
        <AnimatePresence>
          {(isForceOpened || isSoftOpened) && (
            <motion.div
              transition={{
                duration: 0.25,
                easings: "easeOut",
              }}
              style={{ display: shouldHidePopover ? "none" : "flex" }}
              variants={slide}
              initial="exit"
              animate="enter" 
              exit="exit"
            >
              <Spacer
                w={{ base: "0", md: "80px" }}
                onClick={() => dispatch({ type: "FORCE_CLOSE" })}
              />
              <Box
                transition="filter .2s ease-out"
                width="fit-content"
                borderBottomLeftRadius="lg"
                borderBottomRightRadius="lg"
                onMouseEnter={() => dispatch({ type: "CANCEL_SCHEDULED_CLOSE" })}
                onMouseLeave={() => dispatch({ type: "SCHEDULE_CLOSE" })}
                maxH={`calc(100dvh - ${NAVBAR_HEIGHT}px)`}
                overflowY="auto"
                marginTop="-1px"
              >
                <Flex
                  borderBottomRadius={primary.cta_label ? 'none' : 'lg'}
                  overflow="hidden"
                  bg="white"
                >
                  {columns?.map((column: any, index: number) => {
                    if (!column) return null;
                    return renderColumns(column, index, columns.length);
                  })}
                </Flex>

                {primary.cta_label ? (
                  <PrismicLink
                    eventLabel={primary.cta_label}
                    link={primary.cta_link}
                    param={primary.cta_link_param}
                    _hover={{
                      ".arrow-icon": {
                        transform: (theme) => `translateX(${theme.space[1]})`,
                      },
                    }}
                  >
                    <HStack
                      bg="greyLight-3"
                      px="space-40"
                      py="space-28"
                      spacing="space-16"
                      borderBottomRadius="lg"
                      cursor="pointer"
                    >
                      {primary.cta_icon?.document?.__typename === "PrismicElementImage" ? (
                        <IconBox
                          w="space-18"
                          h="space-18"
                          background="none"
                        >
                          <SvgOrImg
                            additionalBoxProps={{
                              maxW: "18px", 
                              maxH: "18px",
                            }}
                            imageFile={primary.cta_icon.document.data?.image_file}
                            isBox={true}
                            size={18}
                          />
                        </IconBox>
                      ) : null}
                      
                      <Text
                        flex="1"
                        fontWeight="medium"
                        fontSize="font-15"
                        lineHeight="short"
                      >
                        {primary.cta_label}
                      </Text>

                      <ShiIcon
                        className="arrow-icon"
                        name="arrowicon"
                        boxSize="18px"
                        transition="transform .2s ease-in-out"
                      />
                    </HStack>
                  </PrismicLink>
                ) : null}
              </Box>
            </motion.div>
          )}
        </AnimatePresence>
      </Box>
      <Box
        as={Box}
        position="fixed"
        top={`${NAVBAR_HEIGHT}px`}
        left="0"
        width="100vw"
        h={`calc(100dvh - ${NAVBAR_HEIGHT}px)`}
        bg="linear-gradient(180deg, rgb(37, 36, 29, 0), rgb(37, 36, 29, .7))"
        visibility={(isForceOpened || isSoftOpened) ? "visible" : "hidden"}
        zIndex="-1"
        opacity="0"
        animation={`${opacityIn} .2s forwards ease-out`}
      />
    </Box>
  );
};

export default SiteHeaderDropdown;
