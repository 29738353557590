import { Box, Badge, BoxProps, VStack, HStack, Text, Image } from "@chakra-ui/react";

import { FC } from "react";

import PrismicLink from "src/atoms/PrismicLink";
import { ShiIcon } from "src/atoms";

export const ADVERTISEMENT_MIN_WIDTH = "340px";
export const ADVERTISEMENT_MIN_HEIGHT = "85px";

const Advertisement: FC<
  { data: Gatsby.PrismicSiteNavbarV2DataBodyAdvertisementItem } & BoxProps
> = ({
  data,
  minHeight = ADVERTISEMENT_MIN_HEIGHT,
  ...rest
}) => {
  if (!data) return null;

  const { background_color, image_top, image_max_width, image_max_height, image_margin, badge, image, title, link, link_param } = data;

  return (
    <Box
      mx={{ base: "space-16", md: "0" }}
      mt={{ base: "0", md: "unset" }}
      mb={{ base: "space-32", md: "0" }}
      overflow="hidden"
      position="relative"
      h="min"
      sx={{
        ":hover": {
          ".element-cta-icon": {
            transform: (theme) => `translateX(${theme.space[1]})`,
          },
        },
      }}
      {...rest}
    >
      <VStack
        align="start"
        w="full"
        h="full"
        spacing={{ base: "0", md: "space-24" }}
      >
        {badge ? (
          <Badge variant="orange" textTransform="uppercase" display={{ base: "none", md: "inline-block" }}>
            {badge}
          </Badge>
        ) : null}

        <PrismicLink
          link={link!}
          param={link_param}
          w="full"
          _hover={{
            ".arrow-icon": {
              transform: (theme) => `translateX(${theme.space[1]})`,
            },
          }}
        >
          <VStack
            pt={image?.document && image_top && image_margin ? { base: "0", md: "space-24"} : "0"}
            bg={background_color || "greyLight-4"}
            borderRadius={{ base: "lg", md: "md"}}
            overflow="hidden"
          >
            {image?.document && image_top ? (
              <Image
                display={{ base: "none", md: "block" }}
                objectFit="contain"
                src={image?.document?.data?.image_file?.url}
                alt={image?.document?.data?.image_file?.alt ?? ""}
                maxW={image_max_width || "80%"}
                maxH={image_max_height || "160px"}
              />
            ) : null}

            <HStack
              spacing="space-16"
              w={{ base: "full", md: ADVERTISEMENT_MIN_WIDTH }}
              h={{ base: "initial", md: minHeight}}
              py={{ base: "space-20", md: "0" }}
              px="space-20"
              pl={image?.document && !image_top && !image_margin ? {base: "space-20", md: "0 !important"} : "space-20"} 
              alignItems="center"
              justifyContent="space-between"
            >
              {image?.document && !image_top ? (
                <Image
                  display={{ base: "none", md: "block" }}
                  objectFit="contain"
                  src={image?.document?.data?.image_file?.url}
                  alt={image?.document?.data?.image_file?.alt ?? ""}
                  mr="space-16"
                  maxW={image_max_width || "160px"}
                  maxH={image_max_height || minHeight}
                />
              ) : null}

              {title ? (
                <Text
                  ml={{ base: "0 !important", md: "0" }} 
                  fontSize="font-15"
                  lineHeight="short"
                  fontFamily="body"
                  fontWeight={{ base: "regular", md: "medium" }}
                >
                  {title}
                </Text>
              ) : null}

              <ShiIcon
                className="arrow-icon"
                name="arrowicon"
                boxSize="18px"
                transition="transform 0.2s ease-in-out"
              />
            </HStack>
          </VStack>
        </PrismicLink>
      </VStack>
    </Box>
  );
};

export default Advertisement;
