import { FC, useState } from "react";
import { Text, Box, Flex, Heading, Button, Badge, useBreakpointValue } from "@chakra-ui/react";

import { IconBox, PrismicLink } from "src/atoms";
import { HEADER_CTA_CLICKED } from "../constants";
import { SvgOrImg } from "src/components/SvgOrImg";

const HeaderSectionButton: FC<{
  item: Exclude<
    ArrayElement<Gatsby.SiteNavbarV2DropdownPanelFragment["items"]>,
    undefined
  >;
  background: boolean;
  type: "primary" | "secondary" | "tertiary" | "tag";
}> = ({ item, background, type }) => {
  const size = useBreakpointValue({ base: 16, md: 18 }) || 16;

  const [isHovered, setIsHovered] = useState(false);
  const colorChanges: [string, string][] = isHovered
    ? [["#555249", "#25241D"]]
    : [["#25241D", "#555249"]];

  const renderButton = () => {
    switch (type) {
      case "primary": // has icon and label
        return (
          <Flex
            alignItems="center"
            justifyContent="center"
            w="full"
            gridGap="space-16"
          >
            {item.icon?.document?.__typename === "PrismicElementImage" ? (
              <IconBox
                w="space-18"
                h="space-18"
              >
                <SvgOrImg
                  additionalBoxProps={{
                    w: "18px", 
                    h: "18px",
                  }}
                  imageFile={item.icon.document.data?.image_file}
                  isBox={true}
                  size={size}
                  colorChanges={colorChanges}
                />
              </IconBox>
            ) : null}
            <Box w="full">
              <Flex justify="flex-start" alignItems="center" w="full" gridGap="space-16">
                <Heading
                  as="h3"
                  fontSize="space-15"
                  lineHeight="none"
                  color="grey-1"
                  className="btn-heading"
                  fontFamily="body"
                  whiteSpace="nowrap"
                  letterSpacing="0"
                >
                  {item.label}
                </Heading>

                {item.badge && (
                  <Badge
                    maxWidth="max-content"
                    height="fit-content"
                    variant="nav-blue"
                    paddingX="space-6"
                    paddingY="space-6"
                    fontSize="font-9"
                    lineHeight="none"
                    textTransform="uppercase"
                  >
                    {item.badge}
                  </Badge>
                )}
              </Flex>
            </Box>
          </Flex>
        );

      case "secondary": //only label
        return (
          <Flex align="center">
            <Box>
              <Text
                as="h3"
                lineHeight="short"
                className="btn-heading"
                variant="body-small"
                fontWeight="medium"
                fontFamily="body"
                whiteSpace="nowrap"
                color="grey-1"
              >
                {item.label}
              </Text>
            </Box>
          </Flex>
        );

      case "tag": //only mobile tertiary link
        return (
          <Text
            as="div"
            py="space-4"
            px="space-8"
            borderRadius="md"
            bg="grey-4"
            variant="body-small"
          >
            {item.label}
          </Text>
        );

      default:
        return null;
    }
  };

  return (
    <Button
      eventLabel={item.label}
      eventName={HEADER_CTA_CLICKED}
      as={PrismicLink}
      variant="ghost"
      link={item.link!}
      justifyContent="flex-start"
      paddingX="0"
      paddingY={{ base: "space-8", md: "0" }}
      w="full"
      param={item.link_param}
      _hover={{
        ".btn-heading": {
          color: "grey-0",
        },
      }}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      {renderButton()}
    </Button>
  );
};

export default HeaderSectionButton;
